<!-- Page header -->
<div class="container-fluid paddingTop">
    <div class="row">
        <h1 class="display-4 display">Messages</h1>
        <!-- Multi download/export buttons -->
        <div *ngIf="showBtnMulti" class="ml-auto">
            <i><span class="mdi mdi-download pointer mdi-size" (click)="multiDownload()" tooltip="Download"></span></i>
            &nbsp;
            <i><span class="mdi mdi-microsoft-excel pointer mdi-size" (click)="multiExport()" tooltip="Export to Excel"></span></i>
        </div>

        <div *ngIf="lstFiltersAll.length > 0" class="btn-group header-btn" [ngClass]="{'ml-auto': !showBtnMulti}" dropdown>
            <div *ngIf="lstFiltersAll.length > 0" class="btn-group ml-auto" dropdown>
                <ng-select placeholder="Select filter"
                           [items]="lstFiltersAll"
                           [multiple]="false"
                           [clearable]="true"
                           bindLabel="Name"
                           [(ngModel)]="objSearchPresetFilters"
                           (ngModelChange)="applyPresetFilter()"
                           (clear)="resetAllFilters(); refreshResults()">
                </ng-select>
            </div>
        </div>

        <button type="button" id="btnFailedMsgs" class="btn" [ngClass]="{'btn-primary': srcAlerts, 'btn-outline-primary': !srcAlerts, 'ml-auto': lstFiltersAll.length <= 0}" [(ngModel)]="srcAlerts" btnCheckbox (ngModelChange)="applyFilters()">
            <i-feather name="zap-off" *ngIf="!srcAlerts"></i-feather> Failed messages
        </button>
        <!-- Reset/Refresh buttons -->
        <div class="header-btn btn-group btn-group-justified">
            <div class="btn-group">
                <button type="button" class="btn btn-outline-secondary" (click)="btnResetClick();">
                    <i-feather name="filter"></i-feather> Reset
                </button>
            </div>
            <div class="btn-group" dropdown>
                <button type="button" [ngClass]="blnAutoRefreshEnabled ? 'btn-secondary' : 'btn-outline-secondary'" class="btn" (click)="btnRefreshClick();">
                    <i-feather name="refresh-cw"></i-feather> Refresh
                </button>
                <button type="button" dropdownToggle [ngClass]="blnAutoRefreshEnabled ? 'btn-secondary' : 'btn-outline-secondary'" class="btn dropdown-toggle dropdown-toggle-split" data-toggle="dropdown">
                    <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul id="dropdown-autorefresh" *dropdownMenu class="dropdown-menu" role="menu">
                    <li role="menuitem"><a class="dropdown-item" [routerLink]="" (click)="btnAutoRefreshClick()">Toggle auto-refresh</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>

<!-- Filters -->
<ng-template #filterStatus>
    <div class="filterwidth">
        <ng-select [items]="lstStatus" [multiple]="true" [clearable]="true" [(ngModel)]="lstSelectedStatus" (click)="stopProp($event)" bindLabel="TransactionStatus" autofocus>
        </ng-select>
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="applyFilters(); popoverStatus.hide();"><i-feather name="filter" class="icon-small"></i-feather> Apply</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearFilter('status'); popoverStatus.hide();"><i-feather name="trash" class="icon-small"></i-feather> Clear</button>
        </div>
    </div>
</ng-template>

<ng-template #filterSender>
    <div class="filterwidth">
        <ng-select [items]="lstParties" [multiple]="true" [clearable]="true" [(ngModel)]="lstSelectedSenders" (click)="stopProp($event)" bindLabel="BusinessName" autofocus>
            <ng-template ng-option-tmp let-item="item">
                <div title="{{item.BusinessName}}">{{item.BusinessName}}</div>
            </ng-template>
        </ng-select>
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="applyFilters(); popoverSender.hide();"><i-feather name="filter" class="icon-small"></i-feather> Apply</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearFilter('sender'); popoverSender.hide();"><i-feather name="trash" class="icon-small"></i-feather> Clear</button>
        </div>
    </div>
</ng-template>

<ng-template #filterReceiver>
    <div class="filterwidth">
        <ng-select [items]="lstParties" [multiple]="true" [clearable]="true" [(ngModel)]="lstSelectedReceivers" (click)="stopProp($event)" bindLabel="BusinessName" autofocus>
            <ng-template ng-option-tmp let-item="item">
                <div title="{{item.BusinessName}}">{{item.BusinessName}}</div>
            </ng-template>
        </ng-select>
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="applyFilters(); popoverReceiver.hide()"><i-feather name="filter" class="icon-small"></i-feather> Apply</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearFilter('receiver'); popoverReceiver.hide();"><i-feather name="trash" class="icon-small"></i-feather> Clear</button>
        </div>
    </div>
</ng-template>

<ng-template #filterMessageType>
    <div class="filterwidth">
        <ng-select [items]="lstMessageTypes" [multiple]="true" [clearable]="true" [(ngModel)]="lstSelectedMessageTypes" (click)="stopProp($event)" bindLabel="BusinessName" autofocus>
            <ng-template ng-option-tmp let-item="item">
                <div title="{{item.BusinessName}}">{{item.BusinessName}}</div>
            </ng-template>
        </ng-select>
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="applyFilters(); popoverMessageType.hide();"><i-feather name="filter" class="icon-small"></i-feather> Apply</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearFilter('messagetype'); popoverMessageType.hide();"><i-feather name="trash" class="icon-small"></i-feather> Clear</button>
        </div>
    </div>
</ng-template>

<ng-template #filterReference>
    <div class="filterwidth">
        <input type="text" #inputReference class="form-control" placeholder="Reference" [(ngModel)]="srcReference" (keyup.enter)="applyFilters(); popoverReference.hide();" (keyup.escape)="btnClick('reset', $event)">
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" (click)="applyFilters(); popoverReference.hide();"><i-feather name="filter" class="icon-small"></i-feather> Apply</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearFilter('reference'); popoverReference.hide();"><i-feather name="trash" class="icon-small"></i-feather> Clear</button>
        </div>
    </div>
</ng-template>

<ng-template #filterDate>
    <div class="filterwidth">
        <div>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="dateinputfrom">From</span>
                </div>
                <input #inputFromDate type="datetime-local" [(ngModel)]="strFromParam" class="form-control" [ngClass]="{'is-invalid': blnFromInvalid}" (keyup.enter)="applyFilters()" (ngModelChange)="fromChanged()">
                <div class="invalid-feedback">
                    {{fromError}}
                </div>
            </div>
            <div class="input-group" id="inputDateTo">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="dateinputto">To</span>
                </div>
                <input type="datetime-local" class="form-control" [ngClass]="{'is-invalid': blnToInvalid}" [(ngModel)]="strToParam" (keyup.enter)="applyFilters()" (ngModelChange)="toChanged()">
                <div class="invalid-feedback">
                    {{toError}}
                </div>
            </div>
        </div>
        <div class="btn-group filterbuttons" role="group" aria-label="Apply or clear filter">
            <button type="button" class="btn btn-secondary btn-sm" [disabled]="!blnApplyBtnDate" (click)="applyFilters(); popoverDate.hide(); dateTextAutoComplete();"><i-feather name="filter" class="icon-small"></i-feather> Apply</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="clearFilter('date'); popoverDate.hide();"><i-feather name="trash" class="icon-small"></i-feather> Clear</button>
        </div>
    </div>
</ng-template>

<!-- Message Table -->
<div class="row paddingTable container-fluid">
    <div class="table-responsive shadow-sm">
        <table class="table table-hover table-sm bg-white rounded messagetable">
            <thead>
                <tr>
                    <th style="text-align: center;"> <!-- Placeholder for checkbox-->
                        <span *ngIf="!btnAllSelected" class="pointer" (click)="selectAll()">
                            <i-feather name="square" class="filtericon"></i-feather>
                        </span>
                        <span *ngIf="btnAllSelected" class="pointer" (click)="deSelectAll()">
                            <i-feather name="check-square" class="filtericon"></i-feather>
                        </span>
                        <!--<button *ngIf="!btnAllSelected" type="button" class="btn btn-outline-secondary btn-sm" (click)="selectAll()">Select all</button>
                        <button *ngIf="btnAllSelected" type="button" class="btn btn-outline-secondary btn-sm" (click)="deSelectAll()">Deselect all</button>
                        -->
                    </th>
                    <th><i-feather *ngIf="!srcAlerts" name="filter" [popover]="filterStatus" placement="right" #popoverStatus="bs-popover" class="filtericon icon-filter" [ngClass]="{'filter-active': blnFilterStatusApplied}"></i-feather></th>
                    <th *ngIf="this.SETTINGS.ShowIdHomeView">ID</th>
                    <th>Date
                        <i-feather name="filter" [popover]="filterDate" placement="right" #popoverDate="bs-popover" class="filtericon icon-filter" [ngClass]="{'filter-active': blnFilterDateApplied}" (onShown)="focusInputFrom()"></i-feather>
                    </th>
                    <th>Message type
                        <i-feather name="filter" [popover]="filterMessageType" #popoverMessageType="bs-popover" class="filtericon icon-filter" [ngClass]="{'filter-active': blnFilterMessageTypeApplied}"></i-feather>
                    </th>
                    <th>Sender
                        <i-feather name="filter" [popover]="filterSender" #popoverSender="bs-popover" class="filtericon icon-filter" [ngClass]="{'filter-active': blnFilterSenderApplied}"></i-feather>
                    </th>
                    <th *ngIf="this.SETTINGS.ShowReceiver">Receiver
                        <i-feather name="filter" [popover]="filterReceiver" #popoverReceiver="bs-popover" class="filtericon icon-filter" [ngClass]="{'filter-active': blnFilterReceiverApplied}"></i-feather>
                    </th>
                    <th class="jef" [attr.colspan]="maxReferences">Reference
                        <i-feather name="filter" [popover]="filterReference" #popoverReference="bs-popover" class="filtericon icon-filter" [ngClass]="{'filter-active': blnFilterReferenceApplied}" (onShown)="focusInputReference()"></i-feather>
                        <div class="tooltip">Use * character to apply wildcard.</div>
                    </th>
                    <th></th>
                    <!-- Placeholder for businessview icon -->
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td [attr.colspan]="totalTableColumns" class="nomsg text-muted" *ngIf="!isLoading && lstMessages.length == 0">
                        No messages fit the filter criteria.
                    </td>
                    <td [attr.colspan]="totalTableColumns" *ngIf="isLoading">
                        <div class="progress">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                Loading messages
                            </div>
                        </div>
                    </td>
                </tr>

                <tr *ngFor="let theMessage of lstMessages" (click)="messageClicked(theMessage)" colspan=100% [ngClass]="{'table-active': (this.SETTINGS.RowHighlight && selectedMessage != -1 && theMessage.Id == selectedMessage),'table-warning': (this.SETTINGS.RowHighlight && theMessage.Status != 0 && theMessage.Status != 255), 'table-danger': (this.SETTINGS.RowHighlight && theMessage.Status == 255)}">
                    <ng-container *ngIf="!isLoading">
                        <td (click)="$event.stopPropagation()" style="text-align: center;">
                            <input type="checkbox" id="{{theMessage.Id}}" name="msgCheckbox" (change)="handleSelected($event, theMessage.Id)" [checked]="btnAllSelected">
                        <td *ngIf="!this.mapStatus.has(theMessage.Status)" class="noicon">
                            <!-- Placeholder for when status icon isn't loaded yet -->
                            <i-feather name="minus-circle"></i-feather>
                        </td>
                        <td *ngIf="this.mapStatus.has(theMessage.Status)" [ngSwitch]="this.mapStatus.get(theMessage.Status).Icon" class="icon" [ngClass]="this.mapStatus.get(theMessage.Status).Text">
                            <i-feather name="check-circle" *ngSwitchCase="'i-check-circle'"></i-feather>
                            <i-feather name="x-circle" *ngSwitchCase="'i-x-circle'"></i-feather>
                            <i-feather name="clock" *ngSwitchCase="'i-clock'"></i-feather>
                        </td>
                        <td *ngIf="this.SETTINGS.ShowIdHomeView">{{theMessage.Id}}</td>
                        <td>{{theMessage.DateCreated | date: this.SETTINGS.DateFormat}}</td>
                        <td>{{theMessage.MessageTypeBusinessName}}</td>
                        <td>
                            <span>{{theMessage.SenderPartyBusinessName}}</span>
                            <i-feather name="loader" class="tableicon" *ngIf="theMessage.SenderPartyAutoGenerated" tooltip="Party derived from other message in Process."></i-feather>
                        </td>
                        <td *ngIf="this.SETTINGS.ShowReceiver">
                            <span>{{theMessage.ReceiverPartyBusinessName}}</span>
                            <i-feather name="loader" class="tableicon" *ngIf="theMessage.ReceiverPartyAutoGenerated" tooltip="Party derived from other message in Process."></i-feather>
                        </td>
                        <td *ngFor="let theReference of theMessage.References">
                            <span *ngIf="appliedSearchParameters" [innerHTML]="theReference.Value[0] | highlight : appliedSearchParameters.reference" tooltip="{{theReference.Key}}"></span>
                            <span *ngIf="!appliedSearchParameters" [innerHTML]="theReference.Value[0]" tooltip="{{theReference.Key}}"></span>
                            <i-feather name="plus-square" class="tableicon" *ngIf="theReference.Value.length > 1"></i-feather>
                        </td>
                        <td *ngFor="let item of createRange(theMessage.References)">
                            <span></span>
                        </td>
                        <td class="icon">
                            <a *ngIf="theMessage.HasViews" (click)="eyeClicked(theMessage); $event.stopPropagation();">
                                <i-feather name="eye" class="text-muted"></i-feather>
                            </a>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- Pagination -->
<!--<span id="version"> Version: {{this.SETTINGS.Version}}</span>-->
<div class="row cntNavigatie" id="paginationcontainer" *ngIf="!isLoading && (pagCurrentPage > 1 || lstMessages.length == pagItemsPerPage)">
    <span class="text-muted txtPagi" id="negMargin">Messages {{(pagCurrentPage * pagItemsPerPage) - pagItemsPerPage + 1}} to {{pagCurrentPage * pagItemsPerPage}}</span>
    <div class="btn-group pagination-margin-right" role="group" aria-label="Pagination">
        <button type="button" class="btn btn-secondary" (click)="pageChanged('first')">First</button>
        <button type="button" class="btn btn-secondary" [disabled]="pagCurrentPage == 1" (click)="pageChanged('previous')">Previous</button>
        <button type="button" class="btn btn-secondary" *ngIf="pagCurrentPage > 25" (click)="pageChanged('-25')">-25</button>
        <button type="button" class="btn btn-secondary" *ngIf="pagCurrentPage > 10" (click)="pageChanged('-10')">-10</button>
        <button type="button" class="btn btn-primary" *ngIf="pagCurrentPage > 5" (click)="pageChanged('-5')">{{pagCurrentPage - 5}}</button>
        <button type="button" class="btn btn-primary" *ngIf="pagCurrentPage > 4" (click)="pageChanged('-4')">{{pagCurrentPage - 4}}</button>
        <button type="button" class="btn btn-primary" *ngIf="pagCurrentPage > 3" (click)="pageChanged('-3')">{{pagCurrentPage - 3}}</button>
        <button type="button" class="btn btn-primary" *ngIf="pagCurrentPage > 2" (click)="pageChanged('-2')">{{pagCurrentPage - 2}}</button>
        <button type="button" class="btn btn-primary" *ngIf="pagCurrentPage > 1" (click)="pageChanged('-1')">{{pagCurrentPage - 1}}</button>
        <button type="button" class="btn btn-primary" [disabled]="true" (click)="pageChanged('current')">{{pagCurrentPage}}</button>
        <button type="button" class="btn btn-primary" *ngIf="((pagCurrentPage)*pagItemsPerPage) < totalMessageCount" (click)="pageChanged('+1')">{{pagCurrentPage + 1}}</button>
        <button type="button" class="btn btn-primary" *ngIf="((pagCurrentPage+1)*pagItemsPerPage) < totalMessageCount" (click)="pageChanged('+2')">{{pagCurrentPage + 2}}</button>
        <button type="button" class="btn btn-primary" *ngIf="((pagCurrentPage+2)*pagItemsPerPage) < totalMessageCount" (click)="pageChanged('+3')">{{pagCurrentPage + 3}}</button>
        <button type="button" class="btn btn-primary" *ngIf="((pagCurrentPage+3)*pagItemsPerPage) < totalMessageCount" (click)="pageChanged('+4')">{{pagCurrentPage + 4}}</button>
        <button type="button" class="btn btn-primary" *ngIf="((pagCurrentPage+4)*pagItemsPerPage) < totalMessageCount" (click)="pageChanged('+5')">{{pagCurrentPage + 5}}</button>
        <button type="button" class="btn btn-secondary" *ngIf="((pagCurrentPage+9)*pagItemsPerPage) < totalMessageCount" (click)="pageChanged('+10')">+10</button>
        <button type="button" class="btn btn-secondary" *ngIf="((pagCurrentPage+24)*pagItemsPerPage) < totalMessageCount" (click)="pageChanged('+25')">+25</button>
        <button type="button" class="btn btn-secondary" [disabled]="lstMessages.length != pagItemsPerPage" (click)="pageChanged('next')">Next</button>
        <!--<button type="button" class="btn btn-secondary" [disabled]="lstMessages.length != pagItemsPerPage" (click)="pageChanged('next')">Last</button>-->
    </div>
    <span class="text-muted txtPagi" id="negMargin">Messages per page</span>
    <ng-select [items]="[25,50,75,100]" [multiple]="false" [clearable]="false" [(ngModel)]="pagItemsPerPage" (ngModelChange)="pagItemsPerPageChange()">
    </ng-select>
</div>
<!-- End of page spacing fix -->
<div style="padding-bottom: 2em;"></div>
<!-- Detail view modal placeholder -->
<app-tnt-detail></app-tnt-detail>
<!-- Businessview modal placeholder -->
<app-tnt-businessview></app-tnt-businessview>